
.topic {

}

.topic__title {
  .forum__qvaluehookresult {
    float: left;
    margin: 0 0.5em 0 0;
  }
}

.forumpost {
  margin-bottom: 1em;
}

.forumpost__content, .forumpost__meta {

}

.forumpost__content {

}

.forumpost__meta {

}

.forumpost__demoname, .forumpost__name, .forumpost__date {
  display: inline-block;
}

.forumpost__name {

}


.forumcontent_meta {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  text-align: right;
  margin-top: 1.125rem;
  @include prop(color, dimmed);
}

.forumpost__edit {
}

.forumpost__date,  .forumpost__gototopic {
  font-size: 0.875rem;
  @include prop(color, dimmed);
}

.forumpost.forumpost--has-demo-name {
  .forumpost__name {
    &::before {
      content: '(';
      display: inline;
    }
    &::after {
      content: ')';
      display: inline;
    }
  }
}

.forumpost__context {
  margin-bottom: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .tag {
    margin: 0.1em;

    span {
      max-width: 20em;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@include normal() {
  .forumpost {
  }

  .forumpost__content {

  }

  .forumpost__meta {

  }

  .forumpost__demoname, .forumpost__name, .forumpost__date {
    display: block;
  }
  .forumpost__demoname {

  }
  .forumpost__name {

  }

  .forumpost__date {

  }

  .forumpost.forumpost--has-demo-name {
    .forumpost__name {

      font-size: 0.875em;
      @include prop(color, dimmed);

      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}

