
.rpt {

  position: relative;

}


.rpt__table {
  position: relative;
  table-layout: fixed;
  border-collapse: collapse;


  th {
    font-weight: 400;
  }

  th, td {
    border-right: 1px solid #aaa;
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    text-align: center;
    background-color: #f8f8f8;

  }

  thead th:first-child {
    left: 0;
    z-index: 1;
    border-right: 1px solid #aaa;
  }

  tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    @include prop(background-color, surface);
    padding: 0.5em;
    text-align: right;
  }

  tbody tr:hover th {

  }

  tbody td {
    vertical-align: top;

    & > div {
      height: 100%;
    }
  }

  tbody tr:nth-child(2n+1) {
    @include prop(background-color, surface);
  }
}

.rpt__profilerow {
  min-height: 3rem;
}

.rpt__timecol-group-heading {
  padding: 1em;
  width: 15em;
  vertical-align: bottom;
}

.rpt__timecol-group-rest {
  padding: 1em;
  vertical-align: bottom;
}
.rpt__timecol {
  width: 15em;
}

.rpt__timecol-selected {
  span {
    color: #d1001e;
  }
}
.rpt__search {
  width: 10em;
  background-color: #f8f8f8;
  padding: 0.5em;
}

.rpt__rowname {
  vertical-align: top;

}

.rpt__cellcontainer {
  padding: 2px;
}

.rpt__cell {
  width: 100%;
  height: 100%;
  min-height: 3rem;

  display: flex;
  flex-direction: column;
  align-content: flex-start;

}

.rpt__cellentry {

}
.rpt__cellentry--row {
  height: 1.2rem;
}
.rpt__cellentryinfo {
  font-size: 0.875em;
}

.rpt__cellentry-seperator {
  font-size: 0.85em;
  margin-top: 0.6em;

}

.rpt__options {

}

.rpt__options-inline {
  display: inline-block;
  margin-top: 0;
}
.rpt__options-end {
}

.rpt__options-inline.rpt__options-end {
  margin-left: auto;
  margin-top: auto;

}

.rpt__option {
  font-size: 0.9em;
  font-weight: 500;
  display: inline-block;
  min-width: 1.2em;
  text-align: center;
  background-color: #ccc;
  color: #000;

  &:hover {
    color: #fff;
    background-color: darkgreen;
  }

  &.rpt__option-set {
    color: #fff;
    background-color: darkgreen;
  }

  .rpt__optionlabel {
    font-weight: 300;
    font-size: 0.8rem;
  }
}

.rpt__searchgroup {

}

.rpt__table-onlyresults .rpt__searchgroup:not(.rpt__searchgroup-found) {
  display: none;
}

.filterbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}

.filterbox__group {
  min-width: 15em;
  padding: 0.2em;

}

.filterbox__collection > div {
  margin: 0.25em 0;
}