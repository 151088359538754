
@import "vars";

@mixin triangle-down($size: 10px, $color: #000) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color
}

@mixin triangle-up($size: 10px, $color: #000) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color
}

@mixin has-ellipsis() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin prop($propname, $varname) {
  @if map-has-key($hrVars, $varname) {
    #{$propname}: map-get($hrVars, $varname);
    #{$propname}: var(--hr-#{$varname}, map-get($hrVars, $varname));
  }
  @else {
    @warn "Unknown hr-Variable `#{$varname}`";
  }
}

@mixin fg($name) {
  @include prop(color, $name);
}

@mixin bg($name) {
  @include prop(background-color, $name);
}

@mixin bordercolor($name) {
  @include prop(border-color, $name);
}

@function hrvalue($name) {
  @if (map_has_key($hrVars, $name)) {
    @return map_get($hrVars, $name)
  }
  @return "INVALID";
}

@mixin set-hr-vars() {
  :root {
    @each $name, $val in $hrVars {
      --hr-#{$name}: #{$val};
    }
  }
}

@mixin bold-font() {
  @include prop(font-weight, title-font-weight);
}

@mixin regular-font() {
  @include prop(font-weight, font-weight);
}

@mixin font-size($size) {
  font-size: 16px * $size;
  font-size: 1rem * $size;
}

@mixin clearfix() {
  content: "";
  display: table;
  table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
  clear: both;
}

@mixin run-tiny-height() {
  @media screen and (max-height: ($run-tiny-height)) {
    @content;
  }
}

@mixin run-small-height() {
  @media screen and (max-height: ($run-small-height)) {
    @content;
  }
}

@mixin small-only() {
  @media screen and (max-width: ($small-screen - 1)) {
    @content;
  }
}

@mixin medium() {
  @media screen and (min-width: $small-screen) {
    @content;
  }
}

@mixin normal() {
  @media screen and (min-width: $medium-screen) {
    @content;
  }
}

@mixin large() {
  @media screen and (min-width: $large-screen) {
    @content;
  }
}

@mixin undecorated-link($color: $color--text-main) {
  color: $color;
  &:hover, &:focus, &:active, &:visited {
    opacity: 1;
    text-decoration: none;
    color: $color;
  }
}

@mixin max-content() {
  max-width: $contentMaxWidth;
}

@mixin container-padding() {
  padding: 1.5rem;
  @include medium() {
    padding: 3rem;
  }
}

@mixin content-padding($vmod: 1, $hmod: 1) {

  padding: ($content__padding_small * $vmod)  ($content__padding_small * $hmod);

  @include medium() {
    padding: ($content__padding_medium * $vmod) ($content__padding_medium * $hmod);
  }
  @include normal() {
    padding: ($content__padding_normal * $vmod) ($content__padding_normal * $hmod);
  }

}

@mixin surface-shadow() {
  box-shadow: 0.2rem 0.1rem 0.5rem $gray3;
}