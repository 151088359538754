
// scrollable-list scrollable-list--items-5 scrollable-list--ih-3



.scrollable-list {

  margin: 0;
  padding: 0;
  height: 10rem;

  &.scrollable-list--padded {
    padding-right: 0.5em;
  }

  .scrollable-list__item {
    height: 2rem;
  }

  .scrollable-list__list {
    margin: 0;
    padding: 0;
  }

  @for $height from 1 through 5 {
    &.scrollable-list--ih-#{$height} {
      height: #{$height * 3}rem;

      .scrollable-list__item {
        height: #{$height}rem;
      }
    }

    @for $items from 2 through 20 {

      &.scrollable-list--items-#{$items} {
        &.scrollable-list--ih-#{$height} {
          height: #{$height *  $items}rem;
        }
      }
    }
  }
}
