

.profile-box {
  position: relative;
  display: flex;
  padding: 0.5rem;
}

.profile-box__avatar {
  flex: 0 1 15%;
  max-width: (3 * 16px * $font__line-height-body);
  min-width: (3 * 16px * $font__line-height-body);
  //margin-right: 0.25rem;
  left: -0.75rem;
  position: relative;

  img {
    max-width: 100%;
    border-radius: 100%;
  }
}

.profile-box__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 100%;
  padding-top: 0.75rem;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    @include regular-font();
  }
}

.profile-box__user {
  @include bold-font();
  font-size: 1.2em;
}

.profile-box__actions {
  list-style-type: none;
  margin: 0;
  padding: 1rem 0 0 0;

  li {
    display: inline-block;
    line-height: 1;

    &, .material-icons {
      font-size: 1rem;
    }

    a {
      padding: 0;
      margin: 0;
      line-height: 1;
      height: auto;

      display: block;
      opacity: 0.8;
      transition: opacity 100ms ease-in-out;
      @include undecorated-link();

      i {
        margin: 0;
        height: 1em;
        width: auto;
        float: none;
        line-height: 1;
      }
    }
  }
}