
.taglist {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.taglist__item {
  .tags, .tag {
    min-width: 0;
    margin: 0;
    flex-wrap: nowrap;
  }

  .tags {
    .tag {
      flex: 0 0 auto;
      & > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .tag:first-child {
      flex: 0 1 auto;
      justify-content: flex-start;
    }
  }
}

.taglist__item:not(:last-child) {
  margin-bottom: 0.5em;
}