

.topbar {

  padding: 1rem 3rem 0 0;
  border-bottom: 1px solid $color__border;
  line-height: 1;

  display: flex;
  align-content: center;


  @include normal() {
    display: block;
    padding: 1rem 3rem 0 3rem;
  }

  .topbar__mainmenu-trigger {

    margin-top: 0.75rem;
    width: 4rem;
    justify-content: center;
    text-align: center;

    .sidenav-trigger {
      display: block;
      float: none;
      @include prop(color, primary);

      &, i {
        line-height: 2rem;
        height: 2rem;
        font-size: 2rem;
        margin: 0;
      }

      i {
        padding: 0;
      }
    }

    @include normal() {
      display: none;
    }

  }


  .topbar__title {
    @include has-ellipsis();
    line-height: 1.2;
    margin: 1rem 0 0 0;
    font-size: 1.5rem;
    @include fg(primary);

  }

  .topbar__content {
    @include max-content();

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    width: 100%;
    height: 100%;
  }

  .topbar__menu {
    text-align: left;
    position: relative;
    top: 1px;

    &.topbar__menu-right {
      text-align: right;
    }

  }
}

.topbar__menu-description {
  display: block;
  padding: 0.5rem 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.topbar__menu-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 1;
  font-size: 0.875rem;
  white-space: nowrap;


  .menu-item__link {
    display: block;
    padding: 0.5rem 1rem;
    margin: 0;
    border-bottom: 2px solid transparent;
    @include prop(color, text);
  }

  .topbar__menu-list-item {
    display: inline-block;

    & .menu-item__link:hover, &.menu-item_active .menu-item__link{
      border-bottom: 2px solid;
      @include prop(border-color, primary);
    }

    &.menu-item_active {
      @include bold-font();
    }

    &.topbar__menu-list-item-up {
      .menu-item__link {
        padding: 0.5rem 0.5rem;
      }
    }
  }

}