
.run-row {
}

.run-row__resultcount {
  font-weight: 400;

  @include prop(color, note);

  .run-row__resultcountvalue {
    @include regular-font();
    @include prop(font-family, font);
    @include prop(color, note);

    padding: 0 0.1em;
  }
}

.chartjs--controls
{
  padding: 0.2rem;
  text-align: right;
  a {
    padding: 0.2rem;
    font-size: 1.2rem;
  }
}

.runresultbox__result {
  display: grid;
  grid-column-gap: 0.2rem;
  grid-row-gap: 0.2rem;
  margin: 0.2rem 0 0.4rem 0;
  align-items: center;
}

.runresultbox__count {
  @include prop(color, text);
  font-weight: 500;
  display: inline-block;
  text-align: right;
}

.runresultbox__label {

}

.runresultbox__label svg {
  width: 28px;
  height: 28px;
}

.runresultbox__graph {
  position: relative;
}

.runresultbox__percent {
  font-size: 0.8em;
  text-align: right;
}

.runresultbox__result, .runresultbox__result.runresultbox__result--graph-inline {
  grid-template-columns: 3rem 3rem 1fr 2.2rem;
  grid-template-rows: 1fr;

  .runresultbox__count   { grid-area: 1 / 1 / 2 / 2; }
  .runresultbox__label   { grid-area: 1 / 2 / 2 / 3; }
  .runresultbox__graph   { grid-area: 1 / 3 / 2 / 4; }
  .runresultbox__percent { grid-area: 1 / 4 / 2 / 5; }
}

.runresultbox__result--graph-block {
  grid-template-columns: 3rem 1fr 2.2rem;
  grid-template-rows: 1fr auto;

  .runresultbox__count   { grid-area: 2 / 1 / 3 / 2; }
  .runresultbox__label   { grid-area: 1 / 2 / 2 / 3; }
  .runresultbox__graph   { grid-area: 2 / 2 / 3 / 3; }
  .runresultbox__percent { grid-area: 2 / 3 / 3 / 4; }
}

.runresultbox__summary  {
  padding: 0 1rem 1rem 1rem;
  .heading {

  }
  .runresultbox__summary-data {
    font-size: 1.25rem;
  }
  @include normal() {
    padding: 0 2.5rem 1em 2.5rem;
    font-size: 1.25rem;
  }
}


.runresultbox__result--hover:hover {
  @include prop(background-color, background);
}

.runresultbox__result.runresultbox__result--condensed {

  margin: 0.1rem 0 0.2rem 0;
  grid-column-gap: 0.2rem;
  grid-row-gap: 0;

  &.runresultbox__result--graph-block {
    grid-template-columns: 2.2rem 1fr 2.2rem;

    .runresultbox__count {
      grid-area: 1 / 1 / 3 / 2;
      align-self: end;
    }

    .runresultbox__label {
      padding-top: 0.1em;
      font-size: 0.875em;
      line-height: 1;
    }
    .runresultbox__percent {
      font-size: 0.75em;
    }

    .runresultbox__percent {
      grid-area: 1 / 3 / 3 / 4;
      align-self: end;
    }
  }
}


/**
 * SMILIE BOX
 */
.runresultbox__result--type-SmilieBox {

  .runresultbox__label {
    position: relative;
    height: 100%;
    span { display: none; }

    &::before {
      display:block;
      content: ' ';
      position: relative;
      left: 0;
      width: 100%;
      height: 1.8rem;
      background: left center no-repeat transparent;
      background-size: contain;
    }
  }

  &[data-value="-2"] .runresultbox__label::before {
    background-image: url(../../images/status/smilies/very_negative_red.svg);
  }
  &[data-value="-1"] .runresultbox__label::before {
    background-image: url(../../images/status/smilies/negative_red.svg);
  }
  &[data-value="0"] .runresultbox__label::before {
    background-image: url(../../images/status/smilies/neutral_red.svg);
  }
  &[data-value="1"] .runresultbox__label::before {
    background-image: url(../../images/status/smilies/positive_red.svg);
  }
  &[data-value="2"] .runresultbox__label::before {
    background-image: url(../../images/status/smilies/very_positive_red.svg);
  }
}

.runresultbox.runresultbox--type-PlusBox {
  display: flex;
  justify-items: stretch;
}
.runresultbox__result--type-PlusBox {
}
.runresultbox__result.runresultbox__result--type-PlusBox {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3.5rem 1fr;
  .runresultbox__graph   { display: none; }

  .runresultbox__count {
    text-align: center;
    grid-area: 1 / 1 / 2 / 2;
  }
  .runresultbox__label   {
    text-align: center;
    align-self: center;
    grid-area: 2 / 1 / 3 / 2;
  }
  .runresultbox__percent {
    text-align: center;
    grid-area: 3 / 1 / 4 / 2;
  }
}

.runresultbox__result.runresultbox__result--type-PlusBox .runresultbox__label {
  max-width: 3rem;
  justify-self: center;
  span {
    max-width: 100%;

    display: block;
  }

  span svg {
    width: 100%;
    height: 100%;
    * {
      @include prop(fill, primary);
    }
  }
}


.runresultbox--type-PlusBox  {
  .runresultbox__result--type-PlusBox[data-value="0"] .runresultbox__label span { padding: 0 45%; }
  .runresultbox__result--type-PlusBox[data-value="1"] .runresultbox__label span { padding: 0 40%; }
  .runresultbox__result--type-PlusBox[data-value="2"] .runresultbox__label span { padding: 0 35%; }
  .runresultbox__result--type-PlusBox[data-value="3"] .runresultbox__label span { padding: 0 30%; }
  .runresultbox__result--type-PlusBox[data-value="4"] .runresultbox__label span { padding: 0 25%; }
  .runresultbox__result--type-PlusBox[data-value="5"] .runresultbox__label span { padding: 0 20%; }
  .runresultbox__result--type-PlusBox[data-value="6"] .runresultbox__label span { padding: 0 15%; }
  .runresultbox__result--type-PlusBox[data-value="7"] .runresultbox__label span { padding: 0 10%; }
  .runresultbox__result--type-PlusBox[data-value="8"] .runresultbox__label span { padding: 0  2%; }
}
