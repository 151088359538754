
.is-vgrow {
  height: 100%;
}

.box > .is-fullwidth {
  width: auto;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.box.is-vgrow {
  display: flex;
  flex-direction: column;
  .box__content {
    height: 100%;
  }
}

.has-ellipsis {
  @include has-ellipsis();

  &.at-end {
    direction: rtl;
    text-align: left;
  }
}

.subtitle {
  &.is-condensed {
    margin-bottom: 0.75rem;
  }
}

.box--has-description {
  .subtitle {
    margin-bottom: 0;
  }

  .box__description {
    margin-bottom: 0.75rem;
  }

  .box__description--wide {
    margin: 1em 0;
  }

}


.box--actions {
  margin: 0.5rem -1.25rem -1.25rem -1.25rem;
  padding: 0 1.25rem 0.25rem 1.25rem;
}

@for $height from 1 through 20 {

  .has-min-height-#{$height} {
    min-height: #{$height}rem;
  }

  .has-height-#{$height} {
    height: #{$height}rem;
  }
}

.columns.is-vend {
  align-items: flex-end;
}