
.forumreply {

  .forumreply__actions {
    text-align: right;
  }
}

.forumreply__notes {
  font-size: 0.75rem;
  @include prop(color, note);
  padding: 0 $box-padding;
}

@include normal() {

  .forumreply__actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .button, span {
      width: 100%;
      margin-top: 0.25em;

    }
  }

}

