
.page__content {
  height: 100%;
}

.page__topbarnotices
{
  .page__notice--warn {
    --notice-color: #{$orange};
    --notice-color-alt: #ffffff;
  }

  .page__notice {
    background-color: var(--notice-color);
    color: var(--notice-color-alt);
  }

  .page__noticecontent {
    box-sizing: content-box;
    @include max-content();
    @include container-padding();

    padding-top: 1em;
    padding-bottom: 1em;

    @include medium() {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;

    & > a {
      flex-grow: 0;
      flex-shrink: 0;

      background-color: var(--notice-color-alt);
      color: var(--notice-color);
      padding: 0.5em 1em;
      border: 1px solid var(--notice-color);

      border-radius: 4px;
      font-weight: 500;

      &:hover {
        background-color: var(--notice-color);
        color: var(--notice-color-alt);
        border: 1px solid var(--notice-color-alt);
      }
    }

    & > p {
      flex-shrink: 0;
      flex-grow: 1;
    }
  }
}
