
.discussion-feed {

}

.discussion-feed--preview {

  padding: 0.2em;

  .discussion-feed__comment {
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .discussion-feed__content {
    display: inline-block;
    text-align: left;
    min-width: 25%;
    max-width: 80%;
    @include prop(background-color, background);
    border-radius: 4px;
    padding: 0.5em;
    font-size: 0.8rem;
  }

  .discussion-feed__info {
    padding: 0 0.5em;
    font-size: 0.8rem;
    @include prop(color, note);
  }
}

.discussion {

  .is-pinned {
    color: $red;
  }


  .ctm-discussion-conclusion, .ctm-discussion-comment {
    header {
      display: flex;

      padding: 10px;
      font-size: 0.8em;

      .left {
        flex: 1;
      }

      .right {
        text-align: right;
        align-self: flex-end;
      }

      .comment-date, .comment-date a, .reply-link{
        text-decoration: none;
        font-size: 1em;
        color: $gray3;
      }
    }
  }

  .ctm-discussion-conclusion {

    background-color: #fff;
    margin-bottom: 1em;
    border: 1px solid $red;

    .conclusion-content {
      padding: 10px;
    }

    .conclusion-info {
      text-decoration: none;
      font-size: 0.8em;
      color: $gray3;
      padding: 0 10px 5px 10px;

      display: flex;
    }

    .conclusion-info a {
      color: $gray3;
      text-decoration: none;
      font-size: 0.8em;

    }

    .left {
      flex: 1;
    }

    .right {
      text-align: right;
      align-self: flex-end;
    }


  }

  .ctm-discussion-comment {
    background-color: #fff;
    border: 1px solid $gray4;



    .comment-content {
      padding: 10px;
      border-top: 1px solid $gray4;
    }

    .comment-context {
      padding: 10px;
      background-color: $gray4;
      font-size: 0.8em;

      .ctm-comment-context-container {
        display: flex;
        color: $gray25;

        .icon {
          width: 2em;
          margin-right: 10px;
          img {
            max-width: 100%;
          }
        }

        .description {
          flex: 1;
        }

        .children {
          width: 5em;
          text-align: right;
        }
      }
    }

    & + .ctm-discussion-comment {
      margin-top: 1em;
    }

    &.comment-self {
      header {
        h3 { color: $red;}
      }
    }
  }

  .ctm-discussion-new-comment {
    margin: 1em 0;

    label[for="form_content"] { display: none;}

    .ctm-button-row {
      text-align: right;
    }

  }


}

.ctm-discussion-standalone {
  max-width: 800px;
  margin: auto;
  margin-bottom: 20px;

  .ctm-discussion-standalone-header {
    margin: 1em 0;
  }
}