

.titlebox {
  display: flex;
  flex-direction: column;
}

.titlebox--growing {
  @include normal() {
    height: 100%;
  }
}

.titlebox__title {
  flex: 0 0 auto;
}

.titlebox__content {
  flex: 1 0 auto;
  align-self: stretch;
}

