
// TODO rename..
.qrun-progress {
  display: flex;

  .qrun-progress__label {

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .qrun-progress__info {
    display: block;
    flex-shrink: 0;
    font-size: 0.8rem;
    align-self: flex-end;
    @include prop(color, note);
  }
}

