
.logo-with-claim {
  text-align: center;
}

.logo-with-claim__logo {
  max-width: 100%;

  &.logo-with-claim__logo_small {
    max-width: 70%;
  }
}

.logo-with-claim__claim {
  @include fg(note);
}

