

.dialog {

}

.dialog__header {
  img {
    max-width: 25%;
  }

}

.dialog__content {

}

.dialog__footer {

}