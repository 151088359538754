.summarybar {

}
.summarybar__group {
  margin-bottom: 0.5rem;
}

.summarybar__group + .summarybar__group {
  margin-top: 1rem;
}

.summarybar__group--divider-top {
  border-top: 1px solid;
  @include prop(border-top-color, background-dark);
}

.summarybar__subgroup {

  .percentage-bar {
    height: 2px !important;
  }

}

.summarybar__searchitems {
  max-height: 50vh;
  padding-bottom: 0.5rem;
}

.summarybar__searchbox {

  input {
    border: 0;
    border-bottom: 1px solid;
    @include prop(border-bottom-color, background-dark);
    font-size: 0.8em;
  }
}