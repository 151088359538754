.formatted-text {

  h1, h2, h3, h4, h5, h6 {
    @include prop(font-weight, title-font-weight);
    @include prop(font, title-font);
    margin-bottom: 0.25em;

    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    font-size: 1.3em;
  }

  h2 {
    font-size: 1.25em;
  }

  h3 {
    font-size: 1.2em;
  }

  h4, h5, h6 {
    font-size: 1em;
  }

  p + p {
    margin-top: 1em;
  }

  ul, ol {
    margin: 0.5em 0 0.5em 1.25em;
    li {
      margin-bottom: 0.375em;
    }
  }
  ul {
    list-style-type: none;
    li {
      left: -1.25em;
      position: relative;
      &::before {
        display: inline-block;
        width: 1.25em;
        text-align: center;
        @include prop(color, primary);
        content: '✓';
      }
    }
  }
  ol {
    list-style-type: decimal;
  }
}