$red: #d1001e;
$green: #b5e61d;
$greenDark: #8ad11a;
$greenDark2: #79b717;
$inactiveRed: #e08991;
$orange: #ff8a00;

$darkGray: #404040;

$contentPadding: 20px;
$contentMaxWidth: 992px;

$content__padding_normal: 1.25rem;
$content__padding_medium: 1.25rem;
$content__padding_small: 1.25rem;

$box-padding: $content__padding_normal;

$gray1: #f8f8f8;
$gray2: #f0f0f0;
$gray3: #979797;
$gray4: #dadada;
$gray25: #3f3f3f;
$gray50: #808080;

$fontPath: '/fonts';

$drawer__width: 288px;
$topbar__height: 32px;

$font__main: Roboto, sans-serif;
$font__weight: 300;
$font__title: Roboto, sans-serif;
$font__title--weight: 500;
$family-sans-serif: $font__main;

$color__text-main: #000;
$color--text-main: #000;

$color__border: rgba(0, 0, 0, 0.12);

$color__link: #000;
$color__link-visited: #000;
$color__link-hover: #404040;

$font--line-height-body: 1.25;
$font__line-height-body: 1.25;

$primary-color: $red;
$primary-highlight: $red;
$primary-color-inverted: #fff;
$secondary-color: $red;

$run-tiny-height: 350px;
$run-small-height: 550px;

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$breadcrumb-item-color: var(--hr-primary);
$box-link-hover-shadow: var(--hr-primary);

//
//$weight-light: var(--hr-font-weight);
//$weight-normal: var(--hr-font-weight);
//$weight-medium: var(--hr-title-font-weight);
//$weight-semibold: var(--hr-title-font-weight);
//$weight-bold: var(--hr-title-font-weight);

// defaults, can be overwritten
// used with mixin prop
// includes current vars for bulma
$hrVars: (
        font: $font__main,
        font-weight: $font__weight,
        title-font: $font__title,
        title-font-weight: $font__title--weight,

        primary: #{$primary-color},
        primary-inverted: #{$primary-color-inverted},
        primary-highlight: $inactiveRed,
        primary-darken: darken($red, 20%),

        primary-lighten-5: lighten($primary-color, 5),
        primary-lighten-10: lighten($primary-color, 10),

        primary-darken-10: darken($primary-color, 10),
        primary-darken-5: darken($primary-color, 5),
        primary-darken-2-5: darken($primary-color, 2.5),
        primary-inverted-darken-5: darken($primary-color-inverted, 5),

        primary-highlight-darken-5: darken($primary-highlight, 5),
        primary-highlight-darken-2-5: darken($primary-highlight, 2.5),

        primary-hue--10deg: adjust_hue($primary-color, -10deg),
        primary-hue-10deg: adjust_hue($primary-color, 10deg),
        primary-hue--10-sat-10: saturate(adjust_hue($primary-color, -10deg), 10),
        primary-hue--10-sat-10-dark-10: darken(saturate(adjust_hue($primary-color, -10deg), 10), 10),
        primary-hue-10-sat-5: saturate(adjust_hue($primary-color, 10deg), 5),
        primary-hue-10-sat-5-light-5: lighten(saturate(adjust_hue($primary-color, 10deg), 5), 5),
        primary-hue-10-sat-10-light-5: lighten(saturate(adjust_hue($primary-color, 10deg), 10), 5),

        secondary: $secondary-color,
        secondary-highlight: #000,
        secondary-darken: #000,

        text: #000000,
        on-primary: #{$primary-color-inverted},

        surface: #fff,

        background: #{$gray2},
        background-dark: #{$gray4},
        bg: #{$gray2},

        dimmed: rgba(0, 0, 0, 0.6),

        on-background: $gray25,

        highlight: #{$gray2},

        note: #{$gray50},

        error: #{$red},

        positive: #{$greenDark},
        neutral: #{$gray25},
        negative: #{$red},
        moderate: #{$orange},
);
