

.xbar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@mixin bo($color: red, $type: solid) {
  //border: 1px $type $color;
}

.xbar__graph {
  flex: 1 0 auto;
  min-height: 15rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;

  @include bo(green);
}


.xbar__axis {
  font-size: 0.875rem;
  margin: 0.5em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;
}

.xbar__table {
  margin-top: 1rem;
  flex: 0 0 auto;
  min-height: 10rem;
  max-height: 20rem;
}

.xbar__tablegroup {
  margin: 0 0 1em 0;
}
.xbar__tablelable {
  font-size: 0.875rem;
  @include prop(font-family, title-font);
  @include prop(font-weight, title-font-weight);
}

.xbar__tablelable:hover, .xbar__tablelable.xbar--selected {
  @include prop(background-color, background);
}

.xbar__selectuser {
  cursor: pointer;
}

.xbar__tablelable.xbar--selected {
  font-style: italic;
}

.xbar__tablerow {
  font-size: 0.875rem;


  &.xbar--selected {
    @include prop(background-color, background);
  }
}

.xbar__tablerowvalue, .xbar__tablelable span {
  @include prop(color, note);
  display: inline-block;
  width: 2rem;
  text-align: right;
  margin-right: 0.5rem;
}


.xbar__selection--filterable.xbar--filtered {
  .xbar__selectable:not(.xbar--selected) {
    display: none;
  }
}

.xbar__bucket {
  flex: 1 1 3rem;
  position: relative;
  z-index: 10;
  justify-content: stretch;

  @include bo(blue);

  display: flex;
  flex-direction: column;

  cursor: pointer;

  &:hover.xbar__selector, &.xbar--selected {
    @include prop(background-color, background);
  }
}

.xbar__bucketdata, .xbar__bucketlabel {

}

.xbar__bucketlabel.xbar--selected {
  @include prop(background-color, background);

  @include prop(font-family, title-font);
  @include prop(font-weight, title-font-weight);
}

.xbar__bucketdata {
  flex: 1 0 auto;
  margin: 0 0.2em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @include bo(orange);
}

.xbar__bucketlabel {
  border-top: 1px solid #666;
  font-size: 0.75rem;
  padding: 0.2em 0;
  text-align: center;
  @include bo(red);
}

.xbar__bucketuserblock {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 100%;
  background-color: gray;
  z-index: 99;
  border: 1px solid gray;
}


.xbar__bucketuserblock:not(.xbar--nolabel):hover,
.xbar__bucketuserblock.xbar--selected
{
  &::before {
    color: #fff;
    display: block;
    width: 100%;
    content: attr(data-value) '%';
    text-align: center;
    font-size: 0.75rem;
    padding: 0.2rem 0;
    position: relative;
  }
}
.xbar__bucketdata {

  &.xbar--primary {
    @include prop(background-color, primary);@include prop(border-color, primary);
  }

  &.xbar--positive .xbar__bucketuserblock {
    background-color: $greenDark;
    border-color: $greenDark;
  }
  &.xbar--negative .xbar__bucketuserblock {
    background-color: $red;
    border-color: $red;
  }
  &.xbar--neutral .xbar__bucketuserblock {
    background-color: $gray25;
    border-color: $gray25;
  }
  &.xbar--moderate .xbar__bucketuserblock {
    background-color: $orange;
    border-color: $orange;
  }
}

.xbar__bucketvalue {
  text-align: right;
  font-size: 0.75rem;
  @include prop(color, dimmed);
  i {
    font-size: 0.625rem;
    opacity: 0.5;
  }
}

.xbar__bucketuserblock:hover,
.xbar__bucketuserblock.xbar--selected {
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, .25);
  z-index: 100;
}

.xbar__bucketuserblock.xbar--primary {
  @include prop(background-color, primary);
  @include prop(border-color, primary);
}

.xbar__bucketdata.xbar--positive,
.xbar__bucketdata.xbar--moderate,
.xbar__bucketdata.xbar--neutral,
.xbar__bucketdata.xbar--negative
{
  .xbar__bucketuserblock.xbar--positive:hover,
  .xbar__bucketuserblock.xbar--positive.xbar--selected,  {
    background-color: $greenDark;
  }
  .xbar__bucketuserblock.xbar--negative:hover,
  .xbar__bucketuserblock.xbar--negative.xbar--selected,  {
    background-color: $red;
  }
  .xbar__bucketuserblock.xbar--neutral:hover,
  .xbar__bucketuserblock.xbar--neutral.xbar--selected,  {
    background-color: $gray25;
  }
  .xbar__bucketuserblock.xbar--moderate:hover,
  .xbar__bucketuserblock.xbar--moderate.xbar--selected,  {
    background-color: $orange;
  }
}
.xbar__bucketuserblock[data-value="0"] {
  display: none;
}

