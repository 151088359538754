
$percentage-bar--height: 0.25rem !default;
$percentage-bar--tick-height: 0.35rem !default;

.summarybar__subgroup {
  .percentage-bar, .percentage-bar__value {
    height: 0.5 * $percentage-bar--height !important;
  }
}

.percentage-bar, .percentage-bar__value {
  transition: all 150ms ease-in;
}

.percentage-bar {
  position: relative;
  @include bg(background-dark);
  height: $percentage-bar--height;
  z-index: 15;
}
.percentage-bar__value:hover {
  &::after {
    display: inline-block;
    opacity: 1;
  }
}
//.percentage-bar__parent:hover, .percentage-bar:hover {
//  .percentage-bar__value::after {
//    display: inline-block;
//    opacity: 1;
//  }
//}

.percentage-bar--notooltip {
  .percentage-bar__parent:hover, .percentage-bar:hover {
    .percentage-bar__value::after {
      display: none;
      opacity: 0;
    }

  }
}
.percentage-bar__tick--light {
  opacity: 0.75;
}
.percentage-bar__tick--results {
  opacity: 0.4;
}

.percentage-bar__tick {
  position: absolute;
  height: 2* $percentage-bar--height;
  top: -0.5 * $percentage-bar--height;
  width: 3px;
  background-color: #000;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  z-index: 17;

  &:hover {
    &::after {
      display: block;
    }
  }
}

.percentage-bar__tick--arrow, .percentage-bar__tick--arrow-down {
  position: absolute;
  height: 100%;
  top: 0;
  width: 1px;
  border: 0;
  background-color: transparent;

  &::after {
    @include triangle-down($percentage-bar--tick-height);
    content: ' ';
    display: block;
    position: relative;
    top: -1 * $percentage-bar--tick-height;
    left: -1 * $percentage-bar--tick-height;
  }

  &.percentage-bar__tick--primary {
    &::after {
      @include prop(border-top-color, primary);
    }
  }
}

.percentage-bar__tick--arrow-up {
  position: absolute;
  height: 100%;
  top: $percentage-bar--height + ($percentage-bar--tick-height * 1.2);
  width: 1px;
  border: 0;
  background-color: transparent;

  &::after {
    @include triangle-up($percentage-bar--tick-height);
    content: ' ';
    display: block;
    position: relative;
    top: -1 * $percentage-bar--tick-height;
    left: -1 * $percentage-bar--tick-height;
  }

  &.percentage-bar__tick--primary {
    &::after {
      @include prop(border-bottom-color, primary);
    }
  }
}

.percentage-bar__tick--back {
  z-index:  13;
}
.percentage-bar__value {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;

  @include bg(primary);
  z-index: 15;

  &::after {
    transition: all 100ms ease-in-out;
    opacity: 0;
    display: inline-block;
    content: attr(data-value);
    position: absolute;
    right: 0;
    bottom: 3px;

    z-index: 18;

    padding: 0.25rem;

    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    font-size: 0.8rem;

    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &[data-value="0%"],
  &[data-value="1%"],
  &[data-value="2%"],
  &[data-value="3%"],
  &[data-value="4%"],
  &[data-value="5%"],
  &[data-value="6%"],
  &[data-value="7%"],
  &[data-value="8%"],
  &[data-value="9%"],
  &[data-value^="1"] {
    &::after {
      left: 0;
      right: unset;
    }
  }
  &[data-value^="100"] {
    &::after {
      right: 0;
      left: unset;
    }
  }
}

@mixin percentage-bar-type($name, $colorName) {
  .percentage-bar_#{$name} .percentage-bar__value {
    @include bg($colorName);
  }
}

@include percentage-bar-type(positive, positive);
@include percentage-bar-type(negative, negative);
@include percentage-bar-type(moderate, moderate);
@include percentage-bar-type(neutral, neutral);

.bar {

  .bar-value {
  }

  .bar-positive {
    background-color: $greenDark;
  }
  .bar-negative {
    background-color: $red;
  }
  .bar-neutral {
    background-color: $gray25;
  }
  .bar-moderate {
    background-color: $orange;
  }
}


.percentage-bar--flex {
  display: flex;
}

.percentage-bar--builtin {
  margin-top: 4px;
}

.percentage-bar--flex .percentage-bar__value {
  position: relative;
  height: $percentage-bar--height;
  top: 0;
  left: 0;
  z-index: 15;
  transition: width 100ms ease-out;
}

.percentage-bar__value-original {
  position: absolute;
  z-index: 14;
}
