body,
button,
input,
select,
optgroup,
textarea {
  color: $color__text-main;
  @include prop(font-family, font);
  @include prop(font-weight, font-weight);
  @include font-size(1);
  line-height: $font__line-height-body;
}


a {
  color: $color__link;
  text-decoration: none;
  &:hover{
    color: $color__link-hover;
  }
}

h1, h2, h3, h4, h5, h6 {
  @include prop(font-family, title-font);
  @include bold-font();
  margin: 0.5rem 0;
}

.text-right {
  text-align: right;
}

.text-dimmed {
  @include prop(color, dimmed);
}
.text-small {
  font-size: 0.875rem;
}

.text-run {
  @include prop(font-family, font);
  @include prop(font-weight, font-weight);
}

.icons-dimmed {
  .fas, .fa, .far,
  &.fas, &.fa, &.far {
    opacity: 0.5;
  }
}