

.header {
  &.has-addons {
    display: flex;

    justify-content: space-between;
    align-content: baseline;
  }

  h1, h2, h3, h4, h5, h6 {
    flex: 1 1 100%;
    margin: 0;
  }
}

.header__addons {
  margin-left: 0.5rem;
  flex: 0 0 auto;
  align-self: flex-end;
}

.header__addons > a {
  padding: 0.2em 0.3em;
}