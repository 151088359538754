.boxlike {
  padding: $box-padding;
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  &.boxlike {
    padding: 0;
    margin: $box-padding;
  }
}

.box + .boxlike {
  padding-top: 0;
}

.box__row {
  padding: ($box-padding / 2) $box-padding;
  width: auto;
  margin-left: -1 * $box-padding;
  margin-right: -1 * $box-padding;
}
.box__row--bg {
  @include prop(background-color, bg);
}

.box__row--disabled {
  pointer-events: none;
  cursor: not-allowed;
  & > * {
    opacity: 0.5;
  }
}

.box {

  & > .undo-box {
    margin-left: (-1 * $box-padding);
    margin-right: (-1 * $box-padding);
    margin-bottom: (-1 * $box-padding);
  }
}

.box.box--condensed {

  margin-bottom: 0;
  padding: ($box-padding / 2) $box-padding;

  & > .undo-box {
    margin-left: (-1 * $box-padding);
    margin-right: (-1 * $box-padding);
    margin-bottom: ($box-padding / -2);
  }

  &:not(:last-of-type) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

}

.box.box--no-padding {
  padding: 0;
}

.box.box--topbox {
  margin-bottom: 0;
  padding: ($box-padding / 2) $box-padding;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.twocolorbox {
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
}

.twocolorbox--mobile-reverse {
  flex-direction: column-reverse;
}

.twocolorbox__first {
  padding: ($box-padding / 2) $box-padding;
  width: 100%;
}

.twocolorbox__second {
  width: 100%;
  padding: ($box-padding / 2) $box-padding;
  @include prop(background-color, background);
  justify-self: flex-end;
}

@include normal() {
  .twocolorbox:not(.twocolorbox--like-mobile) {
    flex-direction: row;

    .twocolorbox__first {
      flex: 1 1 auto;
      padding: ($box-padding / 2) $box-padding;
      width: 100%;
    }

    .twocolorbox__second {
      flex: 0 0 30%;
      width: 30%;
      padding: ($box-padding / 2) $box-padding;
      @include prop(background-color, background);
      justify-self: flex-end;
    }

    .twocolorbox__one-third {
      width: 33%;
    }

    .twocolorbox__two-thirds {
      width: 66%;
    }
  }

}
