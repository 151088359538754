
.matching {

}

.matching__header, .matching__resultcompare {
  display: flex;
}

.matching__header {
  .matching__center {
    align-self: flex-end;
    font-size: 1.2rem;
  }
}

.matching__prev, .matching__next {
  @include prop(color, primary);
}

.matching__left {
  flex: 0 1 50%;
  text-align: left;
}

.matching__right {
  flex: 0 1 50%;
  text-align: right;
}

.matching__center {
  flex: 0 0 3rem;
  text-align: center;
}

.matching__group + .matching__group {
  margin-top: 1rem;
}

.matching__groupheader, .matching__resultheader {
  text-align: center;
  font-size: 1.4rem;
  padding: 0.5em 0.25em;
}

.matching__resultheader {
  padding: 0.2em;
  font-size: 1rem;
}

.matching__result + .matching__result {
  //margin-top: 0.5rem;
}

.matching__resultcompare {
  align-items: center;
}

.matching__result {
  font-size: 1.25rem;
}
.matching__center.matching__comperator {
  font-size: 0.875rem;
}


.matching__result--match .matching__comperator {
  @include prop(color, positive);
}

.matching__result--mismatch .matching__comperator {
  @include prop(color, negative);
}