
.page--login {

  @include small-only() {
    flex-direction: column-reverse;
    .dialogpage {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      flex-shrink: 0;
      flex-grow: 0;
      justify-content: flex-end;
      height: auto;
    }
    .dialogpage__content {
      max-height: 60vh;
    }
    .fullscalebg {
      flex-grow: 1;
      position: relative;
    }
  }

}