

.form {

  .form__row {

    & + .form__row {
      margin-top: $content__padding_small;

      @include medium() {
        margin-top: $content__padding_medium;
      }
      @include normal() {
        margin-top: $content__padding_normal;
      }
    }

    .form__cell {

      &.form__row-centered {
        text-align: center;
      }
    }


  }


}

.form__error {

  margin-top: $content__padding_small;

  @include medium() {
    margin-top: $content__padding_medium;
  }
  @include normal() {
    margin-top: $content__padding_normal;
  }

  @include fg(error);
}
//
//.button {
//  @include bordercolor(primary);
//  @include bg(primary);
//  @include fg(on-primary);
//  &:hover, &:focus, &.button_focused {
//      @include fg(primary);
//      @include bg(on-primary);
//  }
//  &[disabled] {
//    @include prop(color, primary);
//    @include prop(background-color, surface);
//  }
//}

//.dropdown .button {
//  @include bg(surface);
//  @include bordercolor(primary);
//}