@charset "utf-8";

@import "bulma/sass/utilities/functions";

/***********************
 * initial variables
 */
// Colors

$black:        hsl(0, 0%, 4%) !default;
$black-bis:    hsl(0, 0%, 7%) !default;
$black-ter:    hsl(0, 0%, 14%) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey:         hsl(0, 0%, 48%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;
$grey-lightest: hsl(0, 0%, 93%) !default;

$white-ter:    hsl(0, 0%, 96%) !default;
$white-bis:    hsl(0, 0%, 98%) !default;
$white:        hsl(0, 0%, 100%) !default;

$orange:       hsl(14,  100%, 53%) !default;
$yellow:       hsl(48,  100%, 67%) !default;
$green:        hsl(141, 53%,  53%) !default;
$turquoise:    hsl(171, 100%, 41%) !default;
$cyan:         hsl(204, 71%,  53%) !default;
$blue:         hsl(217, 71%,  53%) !default;
$purple:       hsl(271, 100%, 71%) !default;
$red:          hsl(348, 86%, 61%) !default;

// Typography

$family-sans-serif: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-monospace: monospace !default;

$family-primary: var(--hr-font) !default;
$family-secondary: var(--hr-font) !default;
$family-code: $family-monospace !default;

$title-family: var(--hr-title-font) !default;
$subtitle-family: var(--hr-title-font) !default;
/********************
 * derived
 */


$primary: var(--hr-primary) !default;

$info: $cyan !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

// Invert colors
$orange-invert: findColorInvert($orange) !default;
$yellow-invert: findColorInvert($yellow) !default;
$green-invert: findColorInvert($green) !default;
$turquoise-invert: findColorInvert($turquoise) !default;
$cyan-invert: findColorInvert($cyan) !default;
$blue-invert: findColorInvert($blue) !default;
$purple-invert: findColorInvert($purple) !default;
$red-invert: findColorInvert($red) !default;

$primary-invert: var(--hr-primary-inverted) !default;
$primary-light: var(--hr-primary-highlight) !default;
$primary-dark: var(--hr-primary-darken) !default;

$info-invert: findColorInvert($info) !default;
$info-light: findLightColor($info) !default;
$info-dark: findDarkColor($info) !default;
$success-invert: findColorInvert($success) !default;
$success-light: findLightColor($success) !default;
$success-dark: findDarkColor($success) !default;
$warning-invert: findColorInvert($warning) !default;
$warning-light: findLightColor($warning) !default;
$warning-dark: findDarkColor($warning) !default;
$danger-invert: findColorInvert($danger) !default;
$danger-light: findLightColor($danger) !default;
$danger-dark: findDarkColor($danger) !default;
$light-invert: findColorInvert($light) !default;
$dark-invert: findColorInvert($dark) !default;

// TABS

$tabs-link-active-border-bottom-color: $primary !default;
$tabs-link-active-color: $primary !default;
$tabs-toggle-link-active-background-color: $primary !default;
$tabs-toggle-link-active-border-color: $primary !default;


$pagination-focus-border-color: $primary !default;
$pagination-current-background-color: $primary !default;
$pagination-current-border-color: $primary !default;

$calculatedColorsMap: (
  'primary': 'primary',
  'primary-darken-10': 'primary-darken-10',
  'primary-darken-5': 'primary-darken-5',
  'primary-darken-2-5': 'primary-darken-2-5',
  'primary-inverted-darken-5': 'primary-inverted-darken-5',
  'primary-highlight': 'primary-highlight',
  'primary-highlight-darken-5': 'primary-highlight-darken-5',
  'primary-highlight-darken-2-5': 'primary-highlight-darken-2-5',
  'primary-adjust-hue--10deg': 'primary-hue--10deg',
  'primary-adjust-hue-10deg': 'primary-hue-10deg',
  'primary-adjust-hue--10deg-saturate-10': 'primary-hue--10-sat-10',
  'primary-adjust-hue--10deg-saturate-10-darken-10': 'primary-hue--10-sat-10-dark-10',
  'primary-adjust-hue-10deg-saturate-5': 'primary-hue-10-sat-5',
  'primary-adjust-hue-10deg-saturate-5-lighten-5': 'primary-hue-10-sat-5-light-5',


);

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 500 !default;
$weight-bold: 500 !default;

$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;

// $modal-background-background-color: rgba(0, 0, 0, 0.5);

@import "vars";


@function str-replace-int($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace-int(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function get-modified-color($colorName, $valueStr, $function) {

  $isVar: str-index($colorName, 'var(--hr-');
  @if $isVar == null {
    @error "Unsupported Color";
  }

  $valueStrNorm: str-replace-int($valueStr + '', '.', '-');
  $name: str-slice($colorName, 10, -2) + '-' + $function + '-' + $valueStrNorm;
  $var: '--hr-' + $name;

  @if map_has_key($calculatedColorsMap, $name) {
    $name: map_get($calculatedColorsMap, $name);
  }

  @if map_has_key($hrVars, $name) == false {
    @warn "CSS Variable not found in hrVars: " + $name;
  }


  @return 'var(' + $var + ')';
}

@function darken-var($colorName, $percent) {
  @if type_of($colorName) == 'color' {
    @return darken($colorName, $percent)
  }
  @return get-modified-color($colorName, str-slice($percent + '', 0, -2), 'darken')
}

@function lighten-var($colorName, $percent) {
  @if type_of($colorName) == 'color' {
    @return darken($colorName, $percent)
  }

  @return get-modified-color($colorName, str-slice($percent + '', 0, -2), 'lighten')
}

@function adjust-hue-var($colorName, $amount) {
  @if type_of($colorName) == 'color' {
    @return adjust-hue($colorName, $amount)
  }

  @return get-modified-color($colorName, $amount, 'adjust-hue')
}

@function saturate-var($colorName, $amount) {
  @if type_of($colorName) == 'color' {
    @return adjust-hue($colorName, $amount)
  }

  @return get-modified-color($colorName, str-slice($amount + '', 0, -2), 'saturate')
}

@import "bulma/bulma";

