
.login-block {

}

.login-block__header {
  padding: $content__padding_small*2;
  padding-bottom: 0;
  text-align: center;

  @include medium() {
    padding: $content__padding_medium*2;
    padding-bottom: 0;
  }
  @include normal() {
    padding: $content__padding_normal*2;
    padding-bottom: 0;
  }
}

.login-block__content {
  padding: $content__padding_small;
  margin: 0 auto;
  max-width: 256px;

  @include medium() {
    padding: $content__padding_medium;
  }
  @include normal() {
    padding: $content__padding_normal;
  }

  &.login-block__content_centered {
    text-align: center;
  }

}

.login-block__footer {
  padding: $content__padding_small;
  text-align: center;
  @include fg(note);

  @include medium() {
    padding: $content__padding_medium;
    padding-top: 0;
  }
  @include normal() {
    padding: $content__padding_normal;
    padding-top: 0;
  }
  padding-top: 0;
}
