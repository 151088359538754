.topicfeeditem:not(:first-of-type) {
  margin: 1em 0;

}

.topicfeeditem__expandhelper {
  display: none;
}

.box.box--firstpostbox {
  padding-bottom: 0;
}

.topicfeeditem__firstpost {
  display: block;
  margin: 1em auto 0 $box-padding;
  font-size: 0.875rem;
  position: relative;
  opacity: 0.65;
  top: 1em;
  transition: all 200ms ease-out;
  max-height: 3rem;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }
}

.firstpost__meta {
  @include prop(color, note);
}

.firstpost__date, .topicfeeditem__date {
  font-size: 0.875em;
}

.firstpost__content {
  max-height: 2.4em;
  line-height: 1.2;
  overflow: hidden;
  padding-bottom: 0.5em;

  transition: max-height 250ms ease-out;
}

.firstpost__expander {
  cursor: pointer;
  z-index: 200;
  position: absolute;
  opacity: 1;
  transition: all 200ms ease-in-out;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  box-shadow: inset 0 -0.5em 0.5em 0 #fff;
}

.topicfeeditem__expandhelper:not(:checked) {
  & + .topicfeeditem__firstpost {
    &:hover {
      top: 0;
      .firstpost__content {

      }
      .firstpost__expander {

      }
    }
  }
}

.firstpost__showtopic {
  display: none;
  text-align: right;
  padding: 0.25em 0;
}

.topicfeeditem__expandhelper:checked {
  pointer-events: none;
  & + .topicfeeditem__firstpost {
    top: 0;
    opacity: 1;
    max-height: unset;

    .firstpost__expander {
      visibility: hidden;
      display: none;
    }

    .firstpost__content {
      max-height: unset;
    }
    .firstpost__showtopic {
      display: block;
    }
  }


}

.topicfeeditem .forumpost {
  position: relative;
  z-index: 100;
}

.topicfeeditem__firstpost .forumpost {
  margin-bottom: 0;


  .forumpost__demoname, .forumpost__name, .forumpost__date {
    display: inline-block;
  }
}