
@import "fullscalebg";
@import "dialog";
@import "login-block";
@import "form";
@import "logo-with-claim";
@import "profileBox";
@import "menu";
@import "topbar";
@import "page";
@import "content";
@import "percentagebar";
@import "qgraph";
@import "scrollablelist";
@import "discussion";
@import "qrun-progress";
@import "nothing";
@import "runresultbox";
@import "summarybar";
@import "xbar";
@import "titlebox";
@import "formatted-text";
@import "heading";
@import "matching";
@import "taglist";
@import "demoTable";


.page {

  @include prop(background-color, background);

  .page__sidebar {
    li {
      line-height: 1;
    }
  }

  &.page-withtopbar {

  }
  .page__content-wrapper {

    transition: margin-left 200ms ease-in-out;
    @include prop(background-color, background);

    @include normal() {
      margin-left: 300px;
    }
  }

  .page__toolbox-logo-container {

  }

  .page__toolbox-logo {
    line-height: 1;
    margin: 1rem;
    height: 4rem;
    text-align: left;
    vertical-align: bottom;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    a {
      display: block;
      padding: 0 2rem;
    }
    img {
      max-width: 100%;
      max-height: 4rem;
    }
  }

  .page__toolbox-version-info {
    text-align: center;
    font-size: 0.7rem;
    padding: 0.15rem;
    @include prop(color, note);
  }

  .page__topbar {
    max-height: 6rem;
    height: 6rem;
    box-shadow: none;
    @include bg(surface);
    @include fg(text);
  }

  .page__content {
    box-sizing: content-box;

    @include max-content();
    @include container-padding();

    &.wide {
      max-width: 100%;
    }
  }
}

.notice {
  font-size: 0.875rem;
  @include fg(note);
}
