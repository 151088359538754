.nothing {
  display: flex;
  padding: $content__padding_normal / 2;
  opacity: 0.8;
  @include prop(color, note);

  .nothing__icon, .nothing__content {

  }

  .nothing__icon {
    flex-shrink: 0;
    font-size: 1.4rem;
    opacity: 0.8;
    line-height: 1;
    padding: 1.3rem;
  }

  .nothing__content {

  }

  &, &.nothing--horizontal {
    flex-direction: row;
    align-items: center;

    .nothing__icon {

    }

    .nothing__content {
    }

  }

  &.nothing--vertical {
    flex-direction: column;
    text-align: center;
  }


}