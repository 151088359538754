
.fullscalebg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: -1;

  &.fullscalebg__position_right {

    background-position: center right;
  }
}