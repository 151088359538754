

@import "vars";
@import "mixins";

@import "forum/forums";
@import "forum/forums_feeditem";

@import "forum/post";
@import "forum/reply";
@import "forum/content";

.public-forum {
  margin: 1em;
}

.forumfilter {
  font-size: 0.875rem;
  @include prop(color, dimmed);
  list-style-type: none;
  margin: ($box-padding / 2) ($box-padding);
  padding: 0;
  text-align: right;
  li {
    display: inline-block;
    padding: 0 0.25em;
  }
}

.forum--public-link {
  padding: $box-padding;
  font-size: 0.75rem;
  &, a {
    @include prop(color, dimmed);
  }
  span {
    display: block;
  }
}

.forum--previewfeed {

  .forumpost__content {
    font-size: 0.875rem;
    display: block;
    max-height: 2.8rem;
    overflow: hidden;
    text-overflow: fade;
    transition: all ease-out 150ms;
    cursor: pointer;
    @include prop(background-color, background);
    border-radius: 4px;
    padding: 4px;
  }

  .forumpost__meta {
    font-size: 0.75rem;
    padding: 4px;

    &::before {

    }

    span {
      display: inline-block;
    }

    .forumpost__demoname, .forumpost__name, .forumpost__name {
      font-size: 0.875rem;
    }
  }

  .forumpost {
    &:hover {
      .forumpost__content {
        max-height: 3rem;
      }
    }
  }

  .topicfeeditem__expandhelper:checked + .forumpost__content {
    max-height: unset;
  }

}


.runpage--forum .runpage__content {
  @include prop(background-color, background);
}