
@import "components/overlay-scrollbars";


@import "common";

@import "block/block";
@import "page/page";

@import "forum";

@import "reportTable";


.archi-call {
    @include fg(primary);

    &:hover {
        cursor: pointer;
        @include bg(primary);
        @include fg(primary-inverted);
    }
}

.collapse-context {

    .collapse__item {
        display: block;
    }

    .collapse__toggle {
        &::before {
            text-align: center;
            content: attr(data-label-hide);
        }
    }


    .collapse__toggle--icongroup {
        cursor: pointer;
        @include prop(color, dimmed);

        & > .collapse__toggle--show { display: none; }
        & > .collapse__toggle--hide { display: inline; }
    }

    &.collapse-context--collapsed {

        .collapse__item {
            display: none;
        }

        .collapse__toggle {
            &::before {
                content: attr(data-label-show);
            }
        }

        .collapse__toggle--icongroup > .collapse__toggle--show { display: inline; }
        .collapse__toggle--icongroup > .collapse__toggle--hide { display: none; }
    }

}


.is-default-hidden {
    display: none;
}

.profile--box {
    padding: 0;

    .profile__field {
        padding: ($box-padding/4) $box-padding;
    }
}

.profile__field {

    .profile__fieldlabel {
        font-size: 0.875em;
        @include prop(font-family, title-font);
        @include prop(font-weight, title-font-weight);
    }
    .profile__fieldvalue {
        min-height: 1em;
    }

    &.collapse__item {
        @include prop(background-color, background);
    }
}

.profile__grid {
    margin-bottom: 1em;
}

.date-widget > .control {
    display: inline-block;
}

.profile__empty {
    opacity: 0.5;
}

.dropdown {

    .dropdown-item {
        max-width: 80vw;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.modal.is-active {
    z-index: 2700;
}

.is-loading {
    opacity: 0.5;
}

.tag.is-primary.is-inverted {
    @include prop(color, primary);
    @include prop(background-color, primary-inverted);
}

.field.datepicker, .field.timepicker {
    margin-right: 0.2em;
    .control {
        input {
            max-width: 10em;
        }
    }
}

.title-on-hover {
    transition: width 150ms ease-out;
    &:hover {
        &::after {
            content: attr(title);
            padding-left: 0.5em;
            display: inline;
        }
    }
}

.breadcrumb ul, .breadcrumb ol {
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.breadcrumb li {
    & > span {
        padding: $breadcrumb-item-padding-vertical $breadcrumb-item-padding-horizontal;
    }
    &:not(:last-child) {
        & > a, & > span {
            display: inline-block;
            max-width: 10em;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &:last-child {
        overflow: hidden;
        & > a, & > span {
            display: inline-block;

            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}


img.emoji {
    margin: 0 .05em 0 0em;
    vertical-align: -0.2em;
    height: 1.25em;
    width: 1.25em;
}

.runresultbox__label {
    img.emoji {
        margin: 2px;
        height: 1.5em;
        width: 1.5em;
        aspect-ratio: 1;
        max-width: 100%;
    }
}

.clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


.offscreen {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 0;
    height: 0;
}

#color-helper {
    @include prop(color, primary);
}

a.hr-link {
    @include prop(color, primary);
    text-decoration: none;
}

html {
    height: 100%;
}

html, body {
    min-height: 100%;
}

img {
    max-width: 100%;
}

* {
    box-sizing: border-box;
}


body {

}

.modern-scroll-update {
    display: block;
    overflow-y: scroll;
}

.modern-scroll, .scrollable-list {
    display: none;

    &.initialized {
        display: block;
    }
}

.pagination {
    margin: 1em 0;
}

.is-reverse-row-order {
    flex-direction: row-reverse;
}

@keyframes pulsate {
    0% {transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 0.5;}
    100% {transform: scale(1.2, 1.2); opacity: 0.0;}
}

.hinclude-error {
    text-align: center;
    padding: 1rem;
    @include prop(color, primary-darken);
    opacity: 0.5;
    i {
        font-size: 2rem;
    }
    span {
        padding: 0.5em;
        font-size: 0.8rem;
        display: block;
    }
}

h-include-lazy:not(.included) {
    position: relative;
    min-height: 3rem;
    display: block;
    width: 100%;
    text-align: center;

    &:before {
        content: ' ';
        padding: 2rem;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        border: 0.5rem solid rgba(0, 0, 0, 0.1);

        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
       // opacity: 0.0;
    }
}

#totop {
    position: fixed;
    right: 0rem;
    bottom: -10rem;
    padding-bottom: 5rem;
    padding-right: 1rem;
    font-size: 4rem;
    opacity: 0.2;
    transition: all 150ms ease-in;
    &, a, i {
        @include prop(color, primary);
    }

    &.shown {
        bottom: -7rem;
        &:hover {
            opacity: 1;
            bottom: -5rem;
        }
    }
}

li.parsedown-task-list::before {
    display: none !important;
}

h1, h2, h3, h4, h5, h6 {
    &.m-t {
        margin-top: 1em;
    }
}
