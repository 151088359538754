
.sidenav {

  transition: left 200ms ease-in-out;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  left: -300px;
  height: 100%;
  height: 100vh;
  top: 0;
  position: fixed !important;
  width: 300px;
  z-index: 2500;
  @include prop(background-color, surface);

  @include normal() {
    left: 0;
  }
}

.sidenav-bg {
  z-index: 2499;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  transition: opacity 150ms ease-in-out;
}

html.sidenav-open {
  .sidenav {
    left: 0;
  }
  .sidenav-bg {
    display: block;
    opacity: 1;
  }
}

.menu {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}


.menu-item {

}

.menu-item__link {

}

.menu-item__label {

}

.menu-item__icon {

}


.menu_main {

  @include bg(surface);
  @include fg(text);

  .menu-item {
    font-size: 1rem;
    line-height: 1;

    &.menu-item_active, &.menu-item_ancestor {
      @include bg(highlight);
    }

    &.menu-item_active > .menu-item__link, .menu-item__link:hover {
      @include prop(border-left-color, primary);
    }

  }

  .menu-item__link {
    display: flex;
    align-items: center;
//    height: 2.5rem;

    padding: 0.875rem 0.6875rem 0.875rem 0.34375rem;
    border-left: 0.25rem solid transparent;

    @include prop(color, text);

    font-size: 1rem;
    font-weight: normal;

    line-height: 1;
  }

  .menu-item__label {

    flex-basis: 85%;
  }

  .menu-item__icon {
    flex-basis: 10%;
    min-width: 2.5rem;
    text-align: center;
  }

  .menu .menu-item__link {
    @include prop(border-left-color, primary-highlight);
  }

}

.menu .menu_level_1 .menu-item {
  font-size: 1rem;
}

.menu .menu_level_2 .menu-item {
  font-size: 0.875rem;
}

.menu-main-child {
  @include bg(surface);
  @include fg(text);

}

.menu-item.menu-item--group {

}

.menu-item.menu-item--group .menu-item__link {

}

.menu_main .menu-item.menu-item--group {

}
.menu_main .menu-item.menu-item--group > .menu-item__link {
  @include bg(surface);
  @include prop(color, dimmed);
  padding: 0.875rem 0.6875rem 0.125rem 0.34375rem;
  opacity: 0.8;
  font-size: 0.875rem;
}