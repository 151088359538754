

.forumlist {

}

.forumlist__subtitle {
  display: flex;
  align-items: center;
  h1, h2, h3, h4, h5 {
    flex: 1 1 auto;
    margin-bottom: 0 !important;
  }

}



.forumlist__displayswitches {

  a {
    @include prop(color, dimmed);
    transition: all ease-out 100ms;

    svg {
      width: 1.5rem;

    }

    &.active {
      @include prop(color, primary);
    }
    &:hover {
      @include prop(color, text);
    }
  }
}

.forumlist__displayswitches + .forumlist__displayswitches {
  margin-left: 2rem;
}

.forumlist__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .forumlist__topicicon {
    flex: 0 0 auto;
    width: 1.5rem;
    position: relative;
    left: -0.5rem;
    margin: -0.25rem 0;
  }

  .forumlist__name {
    font-size: 1rem;
    line-height: 1.2;
  }

  .forumlist__itemmods {
    @include prop(color, note);
    display: inline-block;
  }

  .forumlist__itemmod {
    font-size: 0.75rem;
    opacity: 0.5;
    transition: opacity 250ms ease-out;
    &:not(:last-child) {
      margin-right: 0.25em;
    }
    &:hover {
      opacity: 1;
    }

    &.forumlist__itemmod--nohover {
      opacity: 1;
    }
  }

  .forumlist__itemtags {
    margin-top: 0.25em;

    .tag {
      justify-content: flex-start;

      & > span {
        max-width: 15rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .forumlist__meta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .forumlist__metaitem {
    font-size: 0.875rem;
    margin-right: 0.5em;
    text-align: center;
    opacity: 0.8;
  }

  .forumlist__metaitemlabel, .forumlist__metaitemicon {
    display: inline-block;
  }
  .forumlist__metaitemicon {
    @include prop(color, note);
  }

  .forumlist__metaitem--postCount, .forumlist__metaitem--topicCount {
    .forumlist__metaitemlabel {
      min-width: 3em;
      text-align: left;
    }
  }

  .forumlist__metaitem--status {
    .forumlist__metaitemlabel {
      min-width: 3em;
      text-align: center;
    }
  }

  @include normal() {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .forumlist__name {
      margin-right: 1em;
      flex: 1 1 auto;

    }

    .forumlist__meta {
      flex: 0 0 auto;
      margin-left: auto;
      width: auto;
    }

  }
}

.forumlist__item--topicoptions {
  justify-content: flex-end;
}


.forumlist__item--category {

}

.forum__itemmod .forum__qvaluehookresult {

}

.forumpost__translator {
  position: relative;
  z-index: 50000;
}
@keyframes issueloading-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.forumlist__item--content {
  margin-top: 0.625rem;
  @include prop(background-color, background);
  transition: all 200ms ease-out;
}

.forumlist__issue.forumlist__issue--loading .forumlist__item--content {
  height: 4px;
  overflow: hidden;

  background: linear-gradient(-45deg, var(--hr-primary), var(--hr-primary-inverted));
  background-size: 400% 400%;
  animation: issueloading-gradient 2s ease infinite;
}

.forumlist__issue:not(.forumlist__issue--expanded) .forumlist__item--content {
  display: none;
}
