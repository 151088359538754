.page--dialogpage {
  display: flex;
}

.dialogpage {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  height: 100%;
  width: 100%;

  @include medium() {
    justify-content: center;
  }

  .dialogpage__content {

    width: 100%;

    @include medium() {
      margin: auto;
      max-width: 45ch;
      min-width: 32ch;
    }

    @include bg(surface);
  }

}