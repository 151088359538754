
.q_graph {
  width: 100%;
  margin: 1rem 0;
}

.q_graph__container {
  text-align: right;
}

.q_graph__list {

  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  height: 5rem;
  position: relative;

  overflow: hidden;
  justify-content: space-evenly;

  &:after {
    display: block;
    content: ' ';
    border-bottom: 1px solid #ccc;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 100;

  }

  li {
    display: inline-block;
    width: 20%;
    font-size: 12px;
    text-align: center;
    position: relative;

    &:before {
      z-index: 200;
      display: block;
      text-align: center;
      content: ' ';
      background-color: #ccc;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin: auto;
      top: 45%;
      top: calc(50% - 6px);
      position: relative;
      border: 1px solid;
      @include prop(border-color, surface);
    }

    span {
      position: relative;
      top: 50%;
      display: block;
      width: 100%;
      text-align: center;
    }

    &:hover:before {
      border: 1px solid #666;
    }

    &.active, &.past {
      &.status-red:before {
        @include bg(negative);
      }

      &.status-orange:before {
        @include bg(moderate);
      }

      &.status-green:before {
        @include bg(positive);
      }
    }
  }

}